<cap-calendar
  styleClass="p-inputgroup"
  [hidden]="to?.readonly"
  [formControl]="formControl"
  [maxDate]="to?.configurationOptions?.max_date"
  [minDate]="to?.configurationOptions?.min_date"
  [showIcon]="true"
  [attr.data-test]="'builder-' + field.key"
  [name]="field.key"
  [dataType]="!to?.configurationOptions?.date ? 'string' : 'date'"
  [placeholder]="formState.readonly ? '' : to?.placeholder"
  [hasTooltip]="to?.tooltip"
  [selectionMode]="to?.selectionMode ?? 'single'"
  [readOnly]="!!formState.readonly"
  [showTime]="to?.configurationOptions?.time"
  [timeOnly]="!to?.configurationOptions?.date"
  [setTimeZone]="to?.configurationOptions?.timezone ?? true"
  [hideOnDateTimeSelect]="true"
  (clickOutside)="onClickOutside()">
</cap-calendar>

<cpb-readonly-container [hidden]="!to?.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  @if (!to?.configurationOptions?.date) {
    {{ formControl.value | default: to?.configurationOptions?.defaultEmptyValue }}
  } @else {
    @if (
      !to?.configurationOptions?.selectionMode ||
      to?.selectionMode === 'single' ||
      (to?.selectionMode !== 'single' && !formControl?.value?.length)
    ) {
      {{ formControl.value | date: (to?.configurationOptions?.time ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy') }}
    } @else {
      {{ formControl.value[0] | date: 'dd-MM-yyyy' }} -
      {{ formControl.value[1] | date: 'dd-MM-yyyy' }}
    }
  }
</cpb-readonly-container>
