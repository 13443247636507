import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-renderer-widget-toggle-input',
  templateUrl: './list-renderer-widget-toggle-input.component.html',
  styleUrls: ['./list-renderer-widget-toggle-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetToggleInputComponent extends ListRendererWidgetBase implements OnInit {
  public endpoint: {
    method: 'POST' | 'GET';
    url: string;
  };

  public done_icon: string;
  public unfinished_icon: string;
  public done = false;
  public isLoading$: Subject<boolean> = new Subject();

  constructor(
    private readonly apiHttp: ApiHttpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.done = !!this.item?.[this.field];
  }

  public toggleStatus(): void {
    const url = this.endpoint?.url?.replace('{{id}}', this.item?.id);

    this.isLoading$.next(true);
    this.apiHttp[this.endpoint.method.toLowerCase()](url, { is_information_shared: this.done ? 0 : 1 }).subscribe({
      next: () => {
        this.done = !this.done;
        this.isLoading$.next(false);
        this.cdr.detectChanges();
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }
}
