<div class="page__header row no-gutters">
  <div [classList]="headingClass">
    <div class="page__heading">
      <ng-content select="[heading]"></ng-content>
    </div>
  </div>

  <div [classList]="centerClass">
    <div class="page__center">
      <ng-content select="[center]"></ng-content>
    </div>
  </div>

  <div [classList]="actionClass">
    <div class="page__actions">
      <ng-content select="[actions]"></ng-content>

      @if (actionMenuList?.length || actionButtons?.length) {
        <span class="dynamic-content" [class.action-menu]="menuRef?.isOpen">
          @if (!isMobile || actionMenuList.length === 1) {
            @for (button of actionButtons; track button) {
              @if ((button?.hidden | observablePipe | async | boolify) === false) {
                <ng-container *ngxPermissionsOnly="button.permissions">
                  @if (button?.type && button.type === ActionButtonType.splitButton && button.subAction?.length) {
                    <p-splitButton
                      [label]="button.label | observablePipe | async"
                      [menuStyle]="{ width: 'auto' }"
                      [model]="button.subAction"
                      (onClick)="button.subAction[0].command()">
                    </p-splitButton>
                  } @else {
                    <cap-button
                      appPreventDoubleClick
                      capButtonLoading
                      [attr.data-test]="button.label"
                      [debounceTime]="button.debounceTime"
                      [disabled]="(button.submitting | observablePipe | async) ?? button.disabled"
                      [icon]="(button.submitting | observablePipe | async) ? 'fas fa-spinner fa-pulse' : button.icon"
                      [label]="button.label | observablePipe | async"
                      [styleClass]="button.styleClass ?? 'primary'"
                      [submitting]="button.submitting | observablePipe | async"
                      (clickEvent)="doAction(button)">
                    </cap-button>
                  }
                </ng-container>
              }
            }
          }
          <span [hidden]="isMobile ? !mobileActionMenuList?.length : !actionMenuList?.length">
            <cap-action-menu
              #menuRef
              [actionItems]="isMobile ? mobileActionMenuList : actionMenuList"
              [hidden]="isMobile ? !mobileActionMenuList?.length : !actionMenuList?.length"
              [openPosition]="isMobile ? ActionMenuPosition.TopLeft : ActionMenuPosition.BottomLeft">
            </cap-action-menu>
          </span>
        </span>
      }
    </div>
  </div>
</div>
