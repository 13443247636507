import { Company } from '@core/models/company.model';
import { StatePageConfig } from '@core/models/page-config.model';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { Confirmation } from 'primeng/api';
import { ChangedItem } from './general-state.model';

export class SetSubmittingStatus {
  public static readonly type = '[GENERAL] Set submitting status';

  constructor(
    public key: string,
    public status: boolean,
  ) {}
}

export class SetActiveCompany {
  public static readonly type = '[GENERAL] Set active company';

  constructor(public company: Company) {}
}

export class SetBackUrl {
  public static readonly type = '[GENERAL] Set backUrl to page config';

  constructor(public backUrl: string) {}
}

export class SetPageConfig {
  public static readonly type = '[GENERAL] Set page config';

  constructor(public config: StatePageConfig) {}
}

export class FetchActionsExecution {
  public static readonly type = '[General] Fetch action execution';

  constructor(
    public key: BuilderActionType,
    public item?: any,
  ) {}
}

export class UpdateChangedItem {
  public static readonly type = '[General] Update changed item';

  constructor(
    public property: string,
    public index: number,
    public item?: ChangedItem,
  ) {}
}

export class ProfileUpdate {
  public static readonly type = '[General] User updated profile';
}

export class ShowConfirmationDialog {
  public static readonly type = '[General] Show confirmation dialog';

  constructor(public confirmation: Confirmation) {}
}

export class InputFocussed {
  public static readonly type = '[General] Save Focused row index';

  constructor(
    public rowIndex: number,
    public fieldName?: string,
  ) {}
}

export class UnloadingLocation {
  public static readonly type = '[General] Save Unloading location';

  constructor(public unloadingLocation: string) {}
}

export class AdditionalPayloadForWidget {
  public static readonly type = '[General] Additional payload for widget';

  constructor(public additionalPayload: object) {}
}

/**
 * @deprecated The method should not be used
 */
export class UpdateRow {
  public static readonly type = '[General] Reset row widget';

  constructor(
    public index: number,
    public item?: object,
    public response?: object,
  ) {}
}

export class SaveListRenderData {
  public static readonly type = '[General] Save list render data';

  constructor(public data: any[]) {}
}
