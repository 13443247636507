import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Store } from '@ngxs/store';
import { ApiHttpService } from '@capturum/api';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ListBackendService implements ActionProvider {
  constructor(
    private readonly apiHttp: ApiHttpService,
    private readonly store: Store,
  ) {}

  public execute(options: ActionOptions, item: any): void {
    this.store.dispatch(new FetchActionsExecution(BuilderActionType.backend, { options, item }));
  }
}
