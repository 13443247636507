<div class="custom-forms">
  <div class="custom-forms__wrapper">
    <cpb-form-renderer
      #formRender
      [context]="config?.data?.context"
      [formKey]="config?.data?.key"
      [modelId]="config?.data?.id || config?.data?.modelId"
      [defaultValue]="config?.data?.defaultValue"
      [showHeader]="false">
    </cpb-form-renderer>
  </div>

  <div class="custom-forms__buttons buttons">
    <cap-button
      (onClick)="ref.close()"
      [attr.data-test]="'cancel-' + entity + '-btn'"
      [label]="config?.data?.cancelLabelkey || 'demooij.form.callbacks.cancel' | translate"
      styleClass="cap-button__cancel">
    </cap-button>

    <div class="buttons__submit">
      @if (hasSubmitAndContinue) {
        <cap-button
          (onClick)="defaultSubmit()"
          [attr.data-test]="'submit-' + entity + '-btn'"
          [disabled]="submitting$ | observablePipe | async"
          [icon]="
            config?.data?.submitIcon ||
            ((submitting$ | observablePipe | async) ? 'fas fa-spinner fa-pulse' : 'fas fa-check')
          "
          [label]="config?.data?.submitLabelkey || 'demooij.form.callbacks.submit' | translate"
          [submitting]="submitting$ | observablePipe | async"
          capButtonLoading
          styleClass="cap-button__submit">
        </cap-button>
      }

      <cap-button
        (onClick)="defaultSubmit(true)"
        [attr.data-test]="'submit-' + entity + '-btn'"
        [disabled]="submitting$ | observablePipe | async"
        [icon]="
          config?.data?.submitIcon ||
          ((submitting$ | observablePipe | async) ? 'fas fa-spinner fa-pulse' : 'fas fa-check')
        "
        [label]="
          config?.data?.submitLabelkey ||
            (hasSubmitAndContinue ? 'demooij.form.callbacks.submit-and-close' : 'demooij.form.callbacks.submit')
            | translate
        "
        [submitting]="submitting$ | observablePipe | async"
        capButtonLoading
        styleClass="cap-button__submit">
      </cap-button>
    </div>
  </div>
</div>
