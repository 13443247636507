<div class="public-layout">
  <div class="public-layout__page">
    <div class="public-layout__header">
      @if (customer$ | async; as customer) {
        <app-header [logoUrl]="customer.logo" [isPublicPage]="true"></app-header>
      }
    </div>

    <div class="public-layout__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
