<div [class]="'panel-content ' + 'row-' + contents?.length">
  @for (content of contents; track content) {
    <div class="title">
      {{ content.title }}
    </div>
    <div class="list-wrapper">
      @if (content?.context) {
        <cpb-list-renderer [ngClass]="content?.key" [key]="content?.key" [context]="content.context">
        </cpb-list-renderer>
      } @else {
        <cap-info-table-skeleton></cap-info-table-skeleton>
      }
    </div>
  }
</div>
