<div class="switch-companies">
  @if (companies$ | async; as companies) {
    @if (companies?.length) {
      <ul class="companies">
        <p class="switch-label">
          {{ 'demooij.user-menu.switch-companies' | translate }}
        </p>
        @for (company of companies; track company) {
          <li [ngClass]="{ active: company?.id === (activeCompany$ | async)?.id }" (click)="changeCompany(company)">
            {{ company?.name }}
          </li>
        }
      </ul>
    }
  } @else {
    <ul class="companies">
      @for (i of [1, 2, 3]; track i) {
        <li>
          <cap-skeleton></cap-skeleton>
        </li>
      }
    </ul>
  }
</div>
