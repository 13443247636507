@if (!hideInput) {
  <div [class.input-prefix]="prefix">
    <span class="prefix">{{ prefix }}</span>
    <cap-input-number
      [class]="'validation-message__' + validation_message_position"
      [class.show-buttons]="showButtons"
      [formControl]="control"
      [selectOnFocus]="true"
      [tabindex]="index + 1"
      locale="de-DE"
      ngDefaultControl
      [disabled]="disabled"
      [mode]="mode"
      [showButtons]="showButtons"
      [buttonLayout]="buttonLayout"
      [min]="min"
      [minFractionDigits]="fractionDigits"
      [maxFractionDigits]="fractionDigits"
      (blur)="setFocus(false)"
      (focus)="setFocus(true)"
      (keyup.Enter)="onKeyEnterEvent()">
    </cap-input-number>
  </div>
}
@if (grayedField) {
  <div>
    <div class="disable-unit"></div>
  </div>
}
