@if (breadcrumbs$ | async; as breadcrumbs) {
  @if (breadcrumbs?.length) {
    @for (breadcrumb of breadcrumbs; track breadcrumb; let index = $index; let last = $last) {
      <div class="breadcrumb-item-wrap">
        @if (breadcrumb.title || breadcrumb.translationKey) {
          <a class="link" [ngClass]="{ 'not-active': last }" (click)="onNavigateClick(breadcrumb)">
            {{ breadcrumb.translationKey ? (breadcrumb.translationKey | translate) : breadcrumb.title }}
          </a>
        }
        @if (!last && (breadcrumb.title || breadcrumb.translationKey)) {
          <span class="arrow"><i class="fas fa-chevron-right"></i></span>
        }
      </div>
    }
  }
}
