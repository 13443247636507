import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SetPageConfig } from '@store/general/general.actions';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { PageConfig } from '@core/models/page-config.model';
import { BreadcrumbRoutingData } from '@core/interfaces/breadcrumb-routing-data.interface';

@Injectable({
  providedIn: 'root',
})
export class PageConfigResolver {
  private backUrl: string;
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.backUrl = route.queryParams?.backUrl;

    return this.setPageConfig(
      route?.data as {
        pageConfig: PageConfig;
        breadcrumb: BreadcrumbRoutingData;
      },
    );
  }

  private setPageConfig({
    pageConfig,
    breadcrumb,
  }: {
    pageConfig: PageConfig;
    breadcrumb: BreadcrumbRoutingData;
  }): Observable<boolean> {
    return this.store
      .dispatch(
        new SetPageConfig({
          title: this.translateService.stream(pageConfig?.title),
          subtitle: pageConfig?.subtitle && this.translateService.stream(pageConfig?.subtitle),
          backUrl: pageConfig?.backUrl ?? this.backUrl,
          warnBeforeRedirect: pageConfig?.warnBeforeRedirect,
          showBreadcrumbs: !!breadcrumb,
        }),
      )
      .pipe(map(Boolean));
  }
}
