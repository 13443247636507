<div class="header-actions">
  @if (headerActions$ | async; as headerActions) {
    @if (headerActions?.length) {
      <ul class="actions">
        @for (action of headerActions; track action) {
          <li [ngClass]="action.styleClass" (click)="onActionCLick(action.callback)">
            <i [class]="action.icon"></i>
            {{ action?.title | translate }}
          </li>
        }
      </ul>
    }
  }
</div>
