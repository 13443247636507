<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.list.action.sales-details' | translate }}
  </div>

  <div class="list-wrapper">
    @if (item?.id && purchaseOrderId) {
      <cpb-list-renderer
        key="list_negative-stock-sales-details"
        [context]="{ product_id: item.id, purchase_order_id: purchaseOrderId }">
      </cpb-list-renderer>
    } @else {
      <cap-info-table-skeleton></cap-info-table-skeleton>
    }
  </div>

  <div class="title">
    {{ 'demooij.negative-stock.information' | translate }}
  </div>

  <div class="list-wrapper">
    @if (item?.id) {
      <cpb-list-renderer [key]="'list' | getKey: Entity.product : Entity.stock" [context]="{ product_id: item?.id }">
      </cpb-list-renderer>
    } @else {
      <cap-info-table-skeleton></cap-info-table-skeleton>
    }
  </div>

  <div class="title">
    {{ 'demooij.entity-name.' + Entity.supplierPrice + '.plural' | translate }}
  </div>

  <div class="list-wrapper">
    @if (item?.id) {
      <cpb-list-renderer key="list_product-negative-stock-supplier-price" [context]="{ product_id: item?.id }">
      </cpb-list-renderer>
    } @else {
      <cap-info-table-skeleton></cap-info-table-skeleton>
    }
  </div>
</div>
