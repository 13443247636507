<div class="autocomplete-input">
  @if (!to?.readonly) {
    <cap-autocomplete
      field="label"
      [(ngModel)]="autocompleteInputValue"
      [placeholder]="to?.readonly ? '' : ('placeholder.select' | translate)"
      [suggestionsFilter]="(results$ | async) || []"
      [delay]="400"
      (completeMethod)="search($event)"
      (onSelect)="customerSelected($event)"
      (blur)="removeCustomer()">
    </cap-autocomplete>
  } @else {
    <cpb-readonly-container>
      {{ autocompleteInputValue.label }}
    </cpb-readonly-container>
  }
  @if (!to?.readonly && showClearButton && autocompleteInputValue) {
    <i class="autocomplete-clear-icon pi pi-times" (click)="clearAll()"> </i>
  }
</div>
