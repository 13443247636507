import { ApplicationRef, Injectable, Injector, Type } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';

@Injectable({
  providedIn: 'root',
})
export class DeMooijDialogService extends DialogService {
  constructor(appRef: ApplicationRef, injector: Injector) {
    super(appRef, injector, document);
  }

  public open(componentType: Type<any>, config: DynamicDialogConfig): DynamicDialogRef {
    document.activeElement['blur']();

    return super.open(componentType, config);
  }
}
