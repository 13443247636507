<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.list.action.allocate-product-detail' | translate }}
  </div>

  @for (listConfig of listConfigs; track listConfig) {
    <div class="list-wrapper">
      @if (item?.id && listConfig) {
        <cpb-list-renderer
          [key]="listConfig.key"
          [context]="listConfig.context"
          (listRendererDataChange)="onListRendererDataChange(listConfig)">
        </cpb-list-renderer>
      } @else {
        <cap-info-table-skeleton></cap-info-table-skeleton>
      }
      @if (listConfig?.averagePrice !== null && listConfig?.averagePrice !== undefined) {
        <div class="separator w-100"></div>
        <div class="average-sales-price p-2 mt-3 d-flex justify-content-end">
          <b class="mr-2">{{ 'demooij.allocation-details.average-price' | translate }}:</b>
          {{ listConfig?.averagePrice | currency: 'EUR' : 'symbol' }}
        </div>
      }
    </div>
  }
</div>
