<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.entity-name.' + Entity.stock + '.plural' | translate }}
  </div>
  <div class="list-wrapper">
    @if (item?.id) {
      <cpb-list-renderer key="list_product-stock" [context]="{ product_id: item?.id }"> </cpb-list-renderer>
    } @else {
      <cap-info-table-skeleton></cap-info-table-skeleton>
    }
  </div>

  <div class="title">
    {{ 'demooij.entity-name.' + Entity.supplierPrice + '.plural' | translate }}
  </div>
  <div class="list-wrapper">
    @if (item?.id) {
      <cpb-list-renderer key="list_product-supplier-price" [context]="{ product_id: item?.id }"> </cpb-list-renderer>
    } @else {
      <cap-info-table-skeleton></cap-info-table-skeleton>
    }
  </div>
</div>
