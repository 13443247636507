import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-renderer-product-description-responsive',
  templateUrl: './list-renderer-product-description-responsive.component.html',
  styleUrls: ['./list-renderer-product-description-responsive.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererProductDescriptionResponsiveComponent extends ListRendererWidgetBase implements OnInit {
  public title: string;
  public subtitle: string;
  public orderNumber: string;
  public checkmark_icon: string;
  public xmark_icon: string;
  public loading_icon: string;
  public parking_icon: string;
  public exclamation_icon: string;
  public eye_icon: string;
  public iconClass: string;
  public isTransfer: boolean;
  public isGoods: boolean;
  public isCompleted: boolean;

  constructor(public readonly router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.isTransfer = this.router.url.includes('transfer');
    this.isGoods = this.router.url.includes('goods');
    this.isCompleted = this.router.url.includes('completed-pick-order-items');

    const iconMap: Record<number, string> = {
      0: 'fas fa-circle-xmark',
      1: 'fas fa-circle-check',
      2: 'fas fa-spinner',
      3: 'fas fa-parking',
      4: 'fas fa-exclamation-circle',
    };

    const description = this.isTransfer
      ? this.item?.description_responsive
      : this.isCompleted
        ? this.item.spotProduct?.product?.description
        : this.item?.product?.description || this.item?.description;

    if (description) {
      const { title, subtitle } = this.processDescription(description);

      this.title = title;
      this.subtitle = subtitle;
    }

    if (this.isCompleted) {
      this.iconClass = iconMap[this.item.item_progress];
    }

    if (this.item?.pick_order_status !== undefined) {
      this.iconClass = iconMap[this.item.pick_order_status];
    }

    this.orderNumber = this.item.purchaseOrder?.order_number ?? this.item.order_number ?? this.item?.order_number_label;
  }

  public processDescription(description: string): { title: string; subtitle: string } {
    const descriptionArray = description?.split(',');

    const title = descriptionArray?.[0];

    descriptionArray?.shift();

    const subtitle = descriptionArray
      ?.map((part) => {
        return part.trim();
      })
      .join(', ');

    return { title, subtitle };
  }
}
