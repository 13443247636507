<div class="product-name">
  @if (item.client) {
    <span class="name">{{ orderNumber }}</span>
    <div>
      <span class="subtitle mr-2">{{ item.client }}</span>
      <span class="subtitle mr-2">{{ item.progress }}</span>
      <i class="subtitle mr-2" [class]="iconClass"></i>
      @if (item.touched_icon && !item.item_progress) {
        <i class="subtitle mr-2" [class]="'fas fa-eye'"></i>
      }
    </div>
  } @else {
    <div>
      @if (isGoods) {
        <span class="name mr-2">{{ item.quantity }}</span>
      }
      <span class="name">{{ title }}</span>
    </div>
    <div>
      @if (subtitle) {
        <span class="subtitle">{{ subtitle }}</span>
      }
      @if (isCompleted) {
        <i class="subtitle ml-2" [class]="iconClass"></i>
      }
    </div>
    <div>
      <span class="subtitle bold">{{ orderNumber }}</span>
      @if (item.destination) {
        <i class="fa-solid fa-arrow-right mr-2 ml-2"></i>
      }
      @if (item.destination) {
        <span class="subtitle bold big">{{ item.destination }}</span>
      }
    </div>
    @if (isCompleted) {
      <div>
        <ng-container
          *ngTemplateOutlet="
            spotInfo;
            context: { spotName: item.spotProduct?.spot?.full_spot_name, icon: 'fa-arrow-left' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            spotInfo;
            context: { spotName: item.spotActivity?.spot?.full_spot_name, icon: 'fa-arrow-right' }
          "></ng-container>
      </div>
    }
  }

  <ng-template #spotInfo let-spotName="spotName" let-icon="icon">
    @if (spotName) {
      <div>
        <i class="fa-solid" [ngClass]="icon + ' mr-2 ml-2'"></i>
        <span class="subtitle bold big">{{ spotName }}</span>
      </div>
    }
  </ng-template>
</div>
