import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Store } from '@ngxs/store';
import { SetPanelConfig } from '@store/panel/panel.actions';
import { PanelComponentType } from '@core/enums/panel-component-type.enum';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { ScreenSize } from '@core/enums/screen-size.enum';

@Injectable({
  providedIn: 'root',
})
export class OpenSidebarService implements ActionProvider {
  public updateSidebarOnNextRowTab = false;
  public isSidebarOpen = false;

  constructor(
    private readonly store: Store,
    private breakPointService: BreakPointService,
  ) {}

  public execute(options: ActionOptions, item: any): void {
    const screenSize = this.breakPointService.getScreenSize();

    if (screenSize !== ScreenSize.desktop) {
      return;
    }

    const componentKey = Object.keys(PanelComponentType).find((key) => {
      return PanelComponentType[key] === options.action.key;
    });

    this.store.dispatch(
      new SetPanelConfig({
        title: this.getItemName(item) || options?.action?.options?.title,
        component: PanelComponentType[componentKey],
        options: { ...options, item },
      }),
    );
  }

  public getItemName(item: any): string {
    if (item?.name) {
      return item.name;
    }

    const descriptionElements = (item?.description ?? item?.product?.description)?.split(',');

    return descriptionElements ? descriptionElements[0] : item?.order_number;
  }
}
