<div class="page">
  <app-page-layout-header
    headingClass="col-3 custom-header"
    actionClass="col-9 custom-header"
    centerClass="custom-header"
    [actionButtons]="actionButtons">
    <div heading>
      {{ 'demooij.entity-name.product.plural' | translate }}
    </div>

    <ng-container actions>
      <label class="align-baseline">
        <b>{{ 'demooij.public.pricey.expires_on' | translate }}</b
        >: {{ (customer$ | async)?.expires_on }}
      </label>
    </ng-container>
  </app-page-layout-header>

  <div class="page__content">
    <div>
      @if (isTableReady) {
        <div class="col-12">
          <app-public-product-list-table
            [editableTable]="true"
            [columns]="columns"
            [tableData]="tableData"
            [showFilters]="true"
            [loadInProgress]="tableDataLoading$ | observablePipe | async"
            [isCashCarryCompany]="isCashCarryCompany"
            (filterChange)="onFiltersChange($event)"
            (tableDataChange)="onTableDataChange($event)">
          </app-public-product-list-table>
        </div>
      }
    </div>
  </div>
</div>

@if (!isBottomReached && (tableDataLoading$ | observablePipe | async) === false) {
  <div class="products-loader">
    <p-progressSpinner class="infinite-scroll"></p-progressSpinner>
  </div>
}
