import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ApiHttpService } from '@capturum/api';
import { BreadcrumbConfiguration } from '@core/interfaces/breadcrumb-routing-data.interface';
import { BreadcrumbItem } from '@core/interfaces/breadcrumb-item.interface';
import { DynamicBreadcrumbItem, DynamicBreadcrumbResponse } from '@core/interfaces/dynamic-breadcrumb-item.interface';
import { Params, Router } from '@angular/router';
import { RouterUtil } from '@core/utils/router-util';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbs$: Subject<BreadcrumbItem[]> = new Subject();

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly apiHttp: ApiHttpService,
    private readonly router: Router,
  ) {}

  public configureBreadcrumbs({ paths, breadcrumbInfo, routeParams }: BreadcrumbConfiguration): void {
    this.unsubscribeFromBreadcrumbs();

    if (!breadcrumbInfo) {
      this.setBreadcrumbs([]);

      return;
    }

    const breadcrumbsObservables$: Observable<BreadcrumbItem | BreadcrumbItem[]>[] = [];

    if (paths?.length >= 1) {
      // Dynamic breadcrumbs are those who have dynamic properties in the route url, for example, :id
      if (
        breadcrumbInfo.dynamic &&
        paths.some((path) => {
          return path.startsWith(':');
        })
      ) {
        const dynamicBodyToSend = paths
          .filter((path) => {
            return path.startsWith(':');
          })
          .map((path) => {
            return path.slice(1, path.length);
          })
          .map((pathId) => {
            const pathDynamicItem = breadcrumbInfo.dynamic.find((dynamicPath) => {
              return dynamicPath.id === pathId;
            });

            return {
              ...pathDynamicItem,
              id: routeParams[pathId],
            };
          });

        breadcrumbsObservables$.push(this.getDynamicBreadcrumbItems(dynamicBodyToSend));
      }

      // Static breadcrumbs are the pages whose title is always the same
      if (
        breadcrumbInfo.static &&
        paths.some((path) => {
          return !path.startsWith(':');
        })
      ) {
        paths
          .filter((path) => {
            return !path.startsWith(':');
          })
          .forEach((path) => {
            const pathStaticItem = breadcrumbInfo.static.find((staticPath) => {
              return staticPath.path === path;
            });

            breadcrumbsObservables$.push(
              of({
                translationKey: pathStaticItem.translationKey,
                path: path,
                order: pathStaticItem.order,
              }),
            );
          });
      }

      // The first item in the breadcrumbs list will be the parent entity
      if (breadcrumbInfo.parent) {
        let params: Params = RouterUtil.getReturnUrl(this.router.url)?.queryParams;

        if (params && params['returnUrl']) {
          const returnUrl = decodeURIComponent(params['returnUrl']);
          const returnUrlParams = returnUrl.split('?')[1];

          params = {};

          if (returnUrlParams) {
            new URLSearchParams(returnUrlParams).forEach((value, key) => {
              return (params[key] = value);
            });
          }
        }

        breadcrumbsObservables$.push(
          of({
            translationKey: breadcrumbInfo.parent.translationKey,
            path: breadcrumbInfo.parent.path,
            order: breadcrumbInfo.parent.order,
            queryParams: params ? params : null,
          }),
        );
      }

      this.listenToBreadcrumbsObservables(breadcrumbsObservables$);
    }
  }

  public unsubscribeFromBreadcrumbs(): void {
    this.destroy$.next(true);
  }

  private listenToBreadcrumbsObservables(observables: Observable<BreadcrumbItem | BreadcrumbItem[]>[]): void {
    combineLatest(observables)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: (BreadcrumbItem | BreadcrumbItem[])[]) => {
        let breadcrumbs = [];

        response.forEach((item) => {
          if (Array.isArray(item)) {
            item.forEach((breadcrumb) => {
              return breadcrumbs.push(breadcrumb);
            });
          } else {
            breadcrumbs.push(item);
          }
        });

        // build full url path for each breadcrumb
        breadcrumbs = breadcrumbs
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((breadcrumb, index, array) => {
            return {
              ...breadcrumb,
              path: array
                .filter((_, breadcrumbIndex) => {
                  return breadcrumbIndex <= index;
                })
                .map((b) => {
                  return b.path;
                })
                .join('/'),
            };
          });

        this.setBreadcrumbs(breadcrumbs);
      });
  }

  private getDynamicBreadcrumbItems(dynamicBreadcrumbItems: DynamicBreadcrumbItem[]): Observable<BreadcrumbItem[]> {
    const params: Params = RouterUtil.getReturnUrl(this.router.url)?.queryParams;

    return this.apiHttp.post<{ data: DynamicBreadcrumbResponse[] }>('/breadcrumb', dynamicBreadcrumbItems).pipe(
      map((response) => {
        return response?.data?.map((breadcrumb: DynamicBreadcrumbResponse) => {
          return {
            title: breadcrumb?.data?.[breadcrumb.key],
            path: `${breadcrumb.id}`,
            order: breadcrumb.order,
            queryParams: params,
          };
        });
      }),
    );
  }

  private setBreadcrumbs(breadcrumbs: BreadcrumbItem[]): void {
    this.breadcrumbs$.next(breadcrumbs);
  }
}
