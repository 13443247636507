<div class="basic-layout mobile">
  <div class="basic-layout__page">
    <div class="basic-layout__header">
      <app-header [showUserMenu]="true" [logoCenter]="true" [logoUrl]="'/assets/images/logo.png'"> </app-header>
    </div>

    <div class="basic-layout__content">
      <div class="cap-sidebar mt-3">
        <ul class="cap-sidebar__menus">
          @for (menu of menus$ | async; track menu) {
            @if (!menu?.hidden) {
              <li [routerLink]="'/' + menu.routerLink">
                <i [class]="menu.icon"></i>
                <span [innerHTML]="menu.label | observablePipe | async"></span>
                <i class="fas fa-chevron-right"></i>
              </li>
            }
          }
        </ul>
      </div>
    </div>
  </div>
</div>
