import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider, BuilderAction } from '@capturum/builders/core';
import { TranslateService } from '@ngx-translate/core';
import { InterpolationPipe, ToastService } from '@capturum/ui/api';
import { ApiHttpService } from '@capturum/api';
import { PollingHandlerService } from '../../public/pricey/services/polling-handler.service';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { EntityAction } from '@core/enums/entity-action.enum';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { Store } from '@ngxs/store';
import { saveAs } from 'file-saver';
import { PublicOrderProductComponent } from '../../public/pricey/pages/public-order-product/public-order-product.component';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ListDownloadService implements ActionProvider {
  public fileToDownload: {
    file_url: string;
    file_name: string;
  };

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly toastNotificationService: ToastNotificationService,
    private readonly interpolationPipe: InterpolationPipe,
    private readonly apiHttp: ApiHttpService,
    private readonly store: Store,
    private readonly pollingHandlerService: PollingHandlerService,
  ) {}

  public execute(options: ActionOptions, item: any): void {
    let endpoint;

    if (options?.action?.options?.executePolling) {
      if (options.configurationKey === PublicOrderProductComponent.name) {
        endpoint = '/batch-status';
      }

      this.downloadWithPolling(options.action, item, endpoint);

      return;
    }

    this.showStartNotification('demooij.toast.download.start');

    this.download(options.action, item);
  }

  private showStartNotification(description: string): void {
    const title = this.translateService.instant('demooij.toast.info.title');
    const message = this.translateService.instant(description);

    this.toastService.info(title, message);
  }

  private download(action: BuilderAction, item: any): void {
    const url = this.interpolationPipe.transform(action.options.endpoint.url, item);

    this.apiHttp
      .get(url, {
        params: action?.options?.endpoint?.params,
        responseType: 'blob',
      })
      .subscribe((result) => {
        this.toastNotificationService.entityActionSuccess('Item', EntityAction.download, true);
        this.store.dispatch(new FetchActionsExecution(BuilderActionType.itemDownload));

        const extension = action.options.endpoint?.extension ?? 'pdf';

        if (result) {
          saveAs(result, this.fileName(item, action, extension));
        }
      });
  }

  private downloadWithPolling(action: BuilderAction, item: any, endpoint: string): void {
    const url = this.interpolationPipe.transform(action.options.endpoint.url, item);

    if (this.pollingHandlerService.pollingInProgress) {
      this.showStartNotification('demooij.toast.wait-for-previous-download');

      return;
    }

    this.showStartNotification('demooij.toast.download.start');

    this.apiHttp
      .get(url, {
        params: action?.options?.endpoint?.params,
      })
      .pipe(
        tap((response: any) => {
          this.fileToDownload = {
            file_url: response.data.url,
            file_name: response.data.file_name + '.pdf',
          };
        }),
        switchMap((res: any) => {
          return this.pollingHandlerService.startPeriodicalPolling(res.data.id, endpoint);
        }),
      )
      .subscribe();
  }

  private fileName(item: any, action: BuilderAction, extension: 'pdf' | 'txt' = 'pdf'): string {
    const fieldName = action.options.entityFieldName ?? 'order_number';

    return (
      item?.fileName ||
      action.options?.filename ||
      item?.filename ||
      item?.file?.filename ||
      `${item?.[fieldName]}.${extension}` ||
      `${item?.id}.${extension}`
    );
  }
}
