<div class="details">
  <div class="details__header">
    <span>{{ title }}</span>

    @if (actions?.length) {
      <div class="icons">
        @for (action of actions; track action) {
          <i [class]="action.icon" tooltipPosition="top" [pTooltip]="action?.tooltip" (click)="executeAction(action)">
          </i>
        }
      </div>
    }
  </div>

  <div class="details__content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
