<router-outlet></router-outlet>
<cap-toast position="top-right"></cap-toast>

@if (loader$ | async) {
  <cap-loader></cap-loader>
}
@if (isAuthenticated$ | async) {
  <p-confirmDialog #cd appendTo="body" (onHide)="onDialogHide()">
    <p-footer>
      <cap-button
        styleClass="secondary"
        type="button"
        [class]="confirmation?.rejectButtonStyleClass"
        [label]="confirmation?.rejectLabel || ('confirm.no' | translate)"
        [attr.data-test]="'reject-confirmation-btn'"
        (onClick)="cd.reject()">
      </cap-button>
      <cap-button
        data-test="dialog-confirm-button"
        type="button"
        [label]="confirmation?.acceptLabel || ('confirm.yes' | translate)"
        [styleClass]="confirmation?.acceptButtonStyleClass || 'primary'"
        [attr.data-test]="'accept-confirmation-btn'"
        (onClick)="cd.accept()">
      </cap-button>
    </p-footer>
  </p-confirmDialog>
}
