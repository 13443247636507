import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { VersionCheckService } from '@capturum/complete';
import { environment } from '@environments/environment';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { catchError, Observable, of, throwError } from 'rxjs';
import { AuthService } from '@capturum/auth';
import { DestroyBase } from '@capturum/shared';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { SetActiveCompany, ShowConfirmationDialog } from '@store/general/general.actions';
import { GeneralSelectors } from '@store/general/general.selectors';
import { Company } from '@core/models/company.model';
import { CompanyColorsEnum } from '@core/enums/company-colors.enum';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { COMPANY_CHANGE_BROADCAST_CHANNEL } from '@core/constants/company_change_broadcast_channel.constant';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { PriceyService } from '@core/api/pricey-api.service';
import { batchStatusInfo, PollingHandlerService } from './public/pricey/services/polling-handler.service';
import { EntityAction } from '@core/enums/entity-action.enum';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ListDownloadService } from '@core/actions/list-download.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [VersionCheckService],
})
export class AppComponent extends DestroyBase implements OnInit, AfterContentChecked, OnDestroy {
  @Select(GeneralSelectors.getActiveCompany)
  public activeCompany$: Observable<Company>;

  @ViewChild(ConfirmDialog) public confirmDialogRef: ConfirmDialog;

  public confirmation: Confirmation;
  public isAuthenticated$: Observable<boolean>;
  public companySwitchedHandler: (company: { data: Company }) => void;
  public readonly channel: BroadcastChannel;
  public loader$ = this.priceyService.showLoader.asObservable();

  private keyPressCallback: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.confirmDialogRef?.accept();
    }
  };

  constructor(
    public readonly cdr: ChangeDetectorRef,
    protected readonly translateService: TranslateService,
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly confirmationService: ConfirmationService,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly actions: Actions,
    private readonly priceyService: PriceyService,
    private readonly pollingHandlerService: PollingHandlerService,
    private readonly toastNotificationService: ToastNotificationService,
    private readonly listDownloadService: ListDownloadService,
    private readonly httpClient: HttpClient,
  ) {
    super();

    try {
      this.channel = new BroadcastChannel(COMPANY_CHANGE_BROADCAST_CHANNEL);
    } catch (e) {
      // continue regardless of error
    }
  }

  public ngOnInit(): void {
    this.fetchVersionChanging();
    this.setTheme();

    this.pollingHandlerService.isBatchDone
      .pipe(
        filter((batchInfo: batchStatusInfo) => {
          return batchInfo.finished_at !== null;
        }),
        switchMap((response: batchStatusInfo) => {
          if (response.failed_jobs > 0) {
            this.toastNotificationService.error(
              this.translateService.instant('demooij.exceptions.file-download_failed'),
            );

            return of(null);
          }

          return this.httpClient.get(this.listDownloadService.fileToDownload.file_url, { responseType: 'blob' }).pipe(
            catchError((error) => {
              return throwError(error);
            }),
          );
        }),
        catchError((error) => {
          this.pollingHandlerService.finishPeriodicalPolling();

          return throwError(error);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((response: Blob) => {
        if (response) {
          this.toastNotificationService.entityActionSuccess('Item', EntityAction.download, true);

          saveAs(response, this.listDownloadService.fileToDownload.file_name);
        }
      });

    this.confirmationService.requireConfirmation$
      .pipe(
        // Debounce Time needed to ensure that this.confirmDialog already rendered on the page
        debounceTime(200),
        takeUntil(this.destroy$),
      )
      .subscribe((confirmation) => {
        // on Enter click, confirm the dialog
        document.addEventListener('keyup', this.keyPressCallback.bind(this));

        this.confirmation = confirmation;
      });

    this.isAuthenticated$ = this.authService.getAuthenticationState();

    this.actions.pipe(ofActionSuccessful(ShowConfirmationDialog)).subscribe(({ confirmation }) => {
      this.confirmationService.confirm(confirmation);
    });

    this.companySwitchedHandler = (company: { data: Company }) => {
      const activeCompany = this.store.selectSnapshot(GeneralSelectors.getActiveCompany);

      if (company.data.code !== activeCompany.code) {
        this.store.dispatch(new SetActiveCompany(company.data));
      }
    };

    if (this.channel) {
      this.channel.addEventListener('message', this.companySwitchedHandler);
    }
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.channel) {
      this.channel.removeEventListener('message', this.companySwitchedHandler);
    }
  }

  public onDialogHide(): void {
    document.removeAllListeners('keyup');
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#ff9500',
      [CSSVarNames.Error]: '#ff3b30',
      '--white': '#ffffff',
      [CSSVarNames.InputHeight]: '40px',
      [CSSVarNames.DynamicFilterBorderRadius]: '25px',
      [CSSVarNames.DynamicFilterBorderWidth]: '2px',
      [CSSVarNames.DynamicFilterInputHeight]: '34px',
      [CSSVarNames.DynamicFilterLabelFontColor]: '#b5b5b5',
      [CSSVarNames.DynamicFilterPlaceholderFontColor]: '#b5b5b5',
      [CSSVarNames.DynamicFilterLabelFontSize]: '13px',
      [CSSVarNames.DynamicFilterIconColor]: '#333',
      [CSSVarNames.DynamicFilterWidth]: '250px',
      [CSSVarNames.DynamicFilterLabelFontWeight]: '600',
      [CSSVarNames.DynamicFilterPlaceholderFontWeight]: '400',
    });

    this.activeCompany$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      return this.themeService.setProps({
        [CSSVarNames.Primary]: CompanyColorsEnum[company?.code] || CompanyColorsEnum.gemotra,
      });
    });
  }

  private fetchVersionChanging(): void {
    this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }
}
