@if (filterConfig) {
  <div class="cap-dynamic-filter-layout">
    <div class="cap-dynamic-filter-layout-content">
      <cap-input
        styleClass="cap-rounded"
        [formControl]="control"
        [disabled]="filterConfig.disabled | observablePipe | async"
        [placeholder]="filterConfig.placeholder">
      </cap-input>
      <div class="filter-icon">
        <i [class]="filterConfig.icon"></i>
      </div>
      @if (control?.value) {
        <div class="delete-filter" (click)="reset()">
          <i class="fas fa-trash-alt"></i>
        </div>
      }
    </div>
  </div>
}
