import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { CompanyApiService } from '@core/api/company-api.service';
import { Store } from '@ngxs/store';
import { SetActiveCompany } from '@store/general/general.actions';
import { map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyResolver {
  constructor(
    private readonly authService: AuthService,
    private readonly companyService: CompanyApiService,
    private readonly store: Store,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.companyService.index().pipe(
      map((results) => {
        return results?.data;
      }),
      tap((companies) => {
        return (this.companyService.companiesList = companies);
      }),
      switchMap((companies) => {
        const activeCompany = companies.find((item) => {
          return item?.id === this.authService.getUser()['current_company_id'];
        });

        this.store.dispatch(new SetActiveCompany(activeCompany || companies[0]));

        return of(true);
      }),
    );
  }
}
