@if (!isMobile) {
  <div class="header {{ logoUrl ? 'logo' : '' }}">
    <div class="row no-gutters">
      @if (logoUrl) {
        <img
          [class.invisible]="logoCenter"
          [ngClass]="{ 'gemotra-logo': isPublicPage && isGemotraCompany }"
          alt="logo"
          [src]="logoUrl" />
      }
      @if (pageConfig$ | async; as config) {
        @if (!config.showBreadcrumbs) {
          <div class="col-4 header__back d-flex justify-content-start">
            <ng-container *ngTemplateOutlet="backUrlContainer; context: { config }"></ng-container>
          </div>
        } @else {
          <app-breadcrumb class="col-4 header__back d-flex justify-content-start"></app-breadcrumb>
        }
        <div [ngClass]="isMobile && isSalesOrders ? 'col-6' : 'col-4'" class="header__location">
          <div class="header__title">
            {{ config.title | observablePipe | async }}
          </div>
          @if (config?.subtitle; as subtitle) {
            <div class="header__connector">-</div>
            <div class="header__subtitle">
              {{ subtitle | observablePipe | async }}
            </div>
          }
        </div>
      } @else {
        <div class="col-3 header__back d-flex justify-content-start"></div>
        <div class="col-6 header__location">
          @if (logoCenter) {
            <img class="logo" alt="logo" [src]="logoUrl" />
          }
        </div>
      }
      @if (showUserMenu) {
        <div class="d-flex justify-content-end header__menu col-4">
          <ng-container *ngTemplateOutlet="userActions"></ng-container>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="mobile d-flex header w-100 align-items-center {{ logoUrl ? 'logo' : '' }}">
    @if (logoUrl && !logoCenter) {
      <img
        [class.invisible]="logoCenter"
        [ngClass]="{ 'gemotra-logo': isPublicPage && isGemotraCompany }"
        alt="logo"
        [src]="logoUrl" />
    }
    <div class="d-flex justify-content-between align-items-center w-100 h-100">
      @if (pageConfig$ | async; as config) {
        @if (!config.showBreadcrumbs || (toShowBreadcrumb$ | async) === false) {
          <div class="header__back">
            <ng-container *ngTemplateOutlet="backUrlContainer; context: { config }"></ng-container>
          </div>
        } @else {
          <app-breadcrumb class="col-4 header__back d-flex justify-content-start"></app-breadcrumb>
        }
        @if (isPublicPage || (toShowMobileTitle$ | async)) {
          <div class="header__location">
            <div class="header__title">
              {{ config?.title | observablePipe | async }}
            </div>
            @if (config?.subtitle; as subtitle) {
              <div class="header__connector">-</div>
              <div class="header__subtitle">
                {{ subtitle | observablePipe | async }}
              </div>
            }
          </div>
        }
      } @else {
        <div class="col-3 header__back d-flex justify-content-start"></div>
        <div class="col-6 header__location">
          @if (logoCenter) {
            <img class="logo" alt="logo" [src]="logoUrl" />
          }
        </div>
      }
      @if (showUserMenu) {
        <div class="header__menu pr-2">
          <ng-container *ngTemplateOutlet="userActions"></ng-container>
        </div>
      }
    </div>
  </div>
}

<ng-template #backUrlContainer let-config="config">
  @if (config?.backUrl; as backUrl) {
    <a (click)="goBack(config)">
      <i class="fa fa-chevron-left"></i>
      <span class="ml-1 nav-btn-back">{{ 'button.back-to-overview' | translate }}</span>
    </a>
  } @else {
    @if (!config?.hideBackUrlMobile) {
      <a class="mobile-back-to-overview" routerLink="/menu">
        <i class="fa fa-arrow-left"></i>
      </a>
    }
  }
</ng-template>

<ng-template #userActions>
  <cap-user-menu [menus]="menus" [user]="user$ | async">
    <div subtitle>{{ (activeCompany$ | async)?.name }}</div>

    <div class="d-flex flex-column" content>
      <app-header-actions (actionClick)="onHeaderActionClick()"></app-header-actions>

      <app-switch-company-list></app-switch-company-list>
    </div>
  </cap-user-menu>
</ng-template>
