import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { filter, first, interval, Observable, Subject, takeUntil, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { INVOICE_FETCH_INTERVAL } from '@core/constants/invoice-fetch-interval.constant';
import { responseData } from '@capturum/builders/core';

export interface batchStatusInfo {
  created_at: string;
  finished_at: string;
  failed_jobs: number;
}

@Injectable({
  providedIn: 'root',
})
export class PollingHandlerService {
  public stopPolling$: Observable<boolean>;
  public isBatchDone: Subject<batchStatusInfo> = new Subject<batchStatusInfo>();

  public pollingInProgress = false;
  public pollingStarted = false;

  public endpoint = `/job-batch/status`;

  private stopPolling: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly apiHttp: ApiHttpService) {
    this.stopPolling$ = this.stopPolling.asObservable();
  }

  public startPeriodicalPolling(batchId: string, endpoint?: string): Observable<any> {
    endpoint && (this.endpoint = endpoint);

    this.pollingStarted = true;

    const timeout = timer(300000);

    timeout.pipe(first()).subscribe(() => {
      if (this.pollingInProgress) {
        const batchInfo = {
          created_at: '',
          finished_at: '',
          failed_jobs: 1,
        };

        this.isBatchDone.next(batchInfo);

        this.finishPeriodicalPolling();
      }
    });

    return this.startPolling(batchId).pipe(
      tap(() => {
        this.pollingInProgress = true;
      }),
      filter((item) => {
        return !!item.finished_at;
      }),
      tap((response: batchStatusInfo) => {
        this.isBatchDone.next(response);
        this.finishPeriodicalPolling();
      }),
      takeUntil(this.stopPolling$),
    );
  }

  public startPolling(batchId: string): Observable<batchStatusInfo> {
    return interval(INVOICE_FETCH_INTERVAL).pipe(
      switchMap((_) => {
        return this.checkForBatchIsDone(batchId);
      }),
    );
  }

  public finishPeriodicalPolling(): void {
    this.stopPolling.next(true);

    this.pollingInProgress = false;
    this.pollingStarted = false;
  }

  private checkForBatchIsDone(batchId: string): Observable<batchStatusInfo> {
    return this.apiHttp.get(`${this.endpoint}/${batchId}`, {}).pipe(responseData);
  }
}
