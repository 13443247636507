@if (showFilters) {
  @if (mainGroupOptions$ | async; as mainGroups) {
    <div class="row">
      <div class="col-lg-10 filters-wrap">
        <span class="label">Filter</span>
        <cap-dynamic-filters
          #dynamicFiltersComponent
          class="public-table-filters"
          [ngClass]="{ 'disabled-overlay': tableDataLoading$ | async }"
          [showResetButton]="false"
          [filterConfig]="dynamicFilters"
          [(activeFilters)]="activeFilters"
          (activeFiltersChange)="handleFilterChange($event)">
        </cap-dynamic-filters>
        <cap-button styleClass="cap-button__gray" [label]="'demooij.btn.reset' | translate" (onClick)="resetFilters()">
        </cap-button>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-end col-lg-2">
        <div class="switch-container" [ngClass]="{ 'd-none': isGemotraCompany }">
          <cap-input-switch [(ngModel)]="loadFullList" (change)="handleLoadAllSwitchChange($event.checked)">
          </cap-input-switch>
          <label>{{ 'demooij.public.pricey.load-full-list' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="main-groups flex-wrap">
      @for (mainGroup of mainGroups; track mainGroup) {
        <div class="checkbox-wrapper">
          <p-checkbox
            #cbox
            [(ngModel)]="selectedMainGroups"
            name="mainGroups"
            [label]="mainGroup.label"
            [value]="mainGroup.key"
            (onChange)="handleMainGroupChange()">
          </p-checkbox>
        </div>
      }
    </div>
  }
}
@if (isTableReady && !loadInProgress) {
  <p-table
    #table
    dataKey="id"
    id="pricey"
    rowGroupMode="subheader"
    groupRowsBy="order"
    styleClass="cap-info-table"
    [value]="tableData"
    [formGroup]="form"
    [ngClass]="{ cashCarry: isCashCarryCompany }">
    @if (!isGemotraCompany) {
      <ng-template pTemplate="header">
        @if (showStickyTopHeader | async) {
          <tr class="table-header sticky">
            @for (column of columns; track column) {
              <th [class]="column.field || ''">
                {{ column.header | translate }}
              </th>
            }
          </tr>
        }
        <tr class="main-invisible-header">
          @for (column of columns; track column) {
            <th [class]="column.field || ''"></th>
          }
        </tr>
        <tr class="main-invisible-header">
          @for (column of columns; track column) {
            <th [class]="column.field || ''"></th>
          }
        </tr>
      </ng-template>
    }
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      @if (!isMobile && (rowData.id | formGroup: form); as rowFormGroup) {
        <tr [formGroup]="rowFormGroup">
          @for (column of columns; track column) {
            <td [class]="column.field">
              @switch (column.template) {
                @case (CustomColumn.unit) {
                  <cap-input-number
                    mode="decimal"
                    [formControl]="column.template | formGroup: rowFormGroup"
                    [selectOnFocus]="true"
                    (blur)="onBlur(rowData.id, column.template)">
                  </cap-input-number>
                }
                @case (CustomColumn.quantity) {
                  <cap-input-number
                    mode="decimal"
                    locale="de-DE"
                    [formControl]="column.template | formGroup: rowFormGroup"
                    [selectOnFocus]="true"
                    (blur)="onBlur(rowData.id, column.template)">
                  </cap-input-number>
                }
                @case (CustomColumn.remarks) {
                  <cap-input
                    [formControl]="column.template | formGroup: rowFormGroup"
                    [selectOnFocus]="true"
                    (blur)="onBlur(rowData.id, column.template)">
                  </cap-input>
                }
                @case (CustomColumn.netPrice) {
                  <span>{{ +rowData[column.field] | number: '1.2-2' : 'nl' }}</span>
                }
                @case (CustomColumn.customerPrice) {
                  <div class="d-flex">
                    <span
                      [ngClass]="{
                        'd-none': column.field === CustomColumn.customerUnitPrice && rowData?.amount_per_package === 1,
                      }">
                      {{ (customer$ | async)?.currency?.prefix }}{{ +rowData[column.field] | number: '1.2-2' : 'nl' }}
                    </span>
                  </div>
                }
                @case (CustomColumn.productName) {
                  <div class="product-name">
                    <div
                      class="desktop product-name-tooltip"
                      [capCellTooltip]="rowData[column.field] | observablePipe | async">
                      {{ rowData[column.field] }}
                    </div>
                  </div>
                }
                @case (CustomColumn.productDetails) {
                  @if (rowData.name; as value) {
                    {{ value }},
                  }
                  @if (rowData.details; as value) {
                    {{ value }},
                  }
                  @if (rowData.size; as value) {
                    {{ value }},
                  }
                  @if (rowData.brand; as value) {
                    {{ value }},
                  }
                  @if (rowData.origin; as value) {
                    {{ value }},
                  }
                  @if (rowData.packages_per_pallet; as value) {
                    {{ value }},
                  }
                  @if (rowData.packaging; as value) {
                    {{ value }}
                  }
                }
                @default {
                  {{ rowData[column.field] }}
                }
              }
            </td>
          }
        </tr>
      }
      @if (isMobile) {
        <tr class="mobile-row">
          <div
            class="clickable-row"
            [ngClass]="{ 'disable-click': isGemotraCompany }"
            (click)="expandedRow.hidden = !expandedRow.hidden">
            @for (column of columns; track column) {
              <td [class]="column.field">
                @switch (column.template) {
                  @case (CustomColumn.productDetails) {
                    @if (rowData.name; as value) {
                      {{ value }},
                    }
                    @if (rowData.details; as value) {
                      {{ value }},
                    }
                    @if (rowData.size; as value) {
                      {{ value }},
                    }
                    @if (rowData.brand; as value) {
                      {{ value }},
                    }
                    @if (rowData.origin; as value) {
                      {{ value }},
                    }
                    @if (rowData.packages_per_pallet; as value) {
                      {{ value }},
                    }
                    @if (rowData.packaging; as value) {
                      {{ value }}
                    }
                  }
                  @case (CustomColumn.customerPrice) {
                    @if (isCashCarryCompany && rowData?.amount_per_package > 1) {
                      <div class="d-flex flex-column cash-and-carry-price">
                        <span class="quantity"
                          >{{ (customer$ | async)?.currency?.prefix
                          }}{{ +rowData[column.field] | number: '1.2-2' : 'nl' }}</span
                        >
                        <span class="unit"
                          >{{ (customer$ | async)?.currency?.prefix
                          }}{{ +rowData.customer_unit_price | number: '1.2-2' : 'nl' }}</span
                        >
                      </div>
                    } @else {
                      <div class="d-flex">
                        <span
                          >{{ (customer$ | async)?.currency?.prefix
                          }}{{ +rowData[column.field] | number: '1.2-2' : 'nl' }}</span
                        >
                      </div>
                    }
                  }
                  @case (CustomColumn.productName) {
                    <div class="product-name">
                      <div class="mobile">
                        <span class="name">{{ rowData.name }}</span>
                        @if (!isGemotraCompany) {
                          <div class="details">
                            @if (rowData.origin) {
                              <span class="detail">{{ rowData.origin }} | &nbsp;</span>
                            }
                            @if (rowData.brand) {
                              <span class="detail">{{ rowData.brand }} | </span>
                            }
                            @if (rowData.details) {
                              <span class="detail">{{ rowData.details }} | &nbsp;</span>
                            }
                            @if (rowData.size) {
                              <span class="detail">{{ rowData.size }} | </span>
                            }
                            <span class="detail"
                              >{{ 'demooij.list.column.net-weight' | translate }}: {{ rowData.net_weight }} |
                              &nbsp;</span
                            >
                            <span class="detail">{{ rowData.packaging }}</span>
                          </div>
                        }
                      </div>
                    </div>
                  }
                }
              </td>
            }
          </div>
          <div #expandedRow [hidden]="true">
            @if (editableTable && rowData?.id) {
              <div class="expanded-row" [formGroup]="rowData.id | formGroup: form">
                <div class="row mb-3">
                  <div class="input-number-wrap col d-flex">
                    <cap-button
                      styleClass="default"
                      label="-"
                      (click)="updateFormValues(false, rowData.id, CustomColumn.quantity)"></cap-button>
                    <cap-input-number
                      mode="decimal"
                      [formControlName]="CustomColumn.quantity"
                      [selectOnFocus]="true"
                      [placeholder]="'demooij.list.column.quantity-short' | translate"
                      [min]="0"
                      (blur)="onBlur(rowData.id, CustomColumn.quantity)">
                    </cap-input-number>
                    <cap-button
                      styleClass="default"
                      label="+"
                      (click)="updateFormValues(true, rowData.id, CustomColumn.quantity)"></cap-button>
                  </div>
                  @if (isCashCarryCompany) {
                    <div class="input-number-wrap col" [ngClass]="{ 'd-none': rowData?.amount_per_package === 1 }">
                      <cap-button
                        styleClass="default"
                        label="-"
                        [disabled]="form?.get(rowData.id)?.get(CustomColumn.unit)?.disabled"
                        (onClick)="updateFormValues(false, rowData.id, CustomColumn.unit)">
                      </cap-button>
                      <cap-input-number
                        mode="decimal"
                        [placeholder]="'demooij.list.column.unit' | translate"
                        [formControlName]="CustomColumn.unit"
                        [selectOnFocus]="true"
                        [min]="0"
                        (blur)="onBlur(rowData.id, CustomColumn.unit)">
                      </cap-input-number>
                      <cap-button
                        styleClass="default"
                        label="+"
                        [disabled]="form?.get(rowData.id)?.get(CustomColumn.unit)?.disabled"
                        (onClick)="updateFormValues(true, rowData.id, CustomColumn.unit)">
                      </cap-button>
                    </div>
                  }
                </div>
                <cap-input
                  [formControlName]="CustomColumn.remarks"
                  [selectOnFocus]="true"
                  [placeholder]="'demooij.list.column.remarks' | translate"
                  (blur)="onBlur(rowData.id, CustomColumn.remarks)">
                </cap-input>
              </div>
            }
          </div>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="groupheader" let-rowData>
      <tr pRowGroupHeader>
        <td [attr.colspan]="columns.length">
          <div class="main-group-name" [ngClass]="{ first: first }">
            <div class="separator"></div>
            <div class="name">{{ rowData.main_group_description }}</div>
            <div class="separator"></div>
          </div>
        </td>
      </tr>
      <tr class="table-header" [ngClass]="{ 'd-none': isMobile }">
        @for (column of columns; track column) {
          <td [class]="column.field || ''">
            {{ column.header | translate }}
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      @if (columns) {
        <tr class="empty-message-row text-center">
          <td [attr.colspan]="columns.length">
            {{ 'table.no_results' | translate }}
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
} @else {
  <cap-info-table-skeleton></cap-info-table-skeleton>
}
