@if (item[field]) {
  <div>
    <button
      class="fa-solid fa-triangle-exclamation"
      tooltipStyleClass="icon-tooltip"
      tooltipEvent="{{ isMobile ? 'focus' : 'hover' }}"
      pTooltip="{{ item[field] }}"
      tooltipPosition="top"></button>
  </div>
}
