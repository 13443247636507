import { takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { BreakPointService } from './../../services/breakpoint.service';
import { Router } from '@angular/router';
import { ActionButton, ActionButtonType } from '@core/models/action-button.model';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActionMenuComponent, OpenPositionsActionMenu } from '@capturum/ui/action-menu';

@Component({
  selector: 'app-page-layout-header',
  templateUrl: './page-layout-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutHeaderComponent extends DestroyBase implements OnChanges {
  @Input() public headingClass = 'col-3';
  @Input() public actionClass = 'col-9';
  @Input() public centerClass?: string;
  @Input() public actionButtons: ActionButton[] = [];
  @ViewChild(ActionMenuComponent)
  public actionMenuComponent: ActionMenuComponent;

  public isMobile: boolean;
  public ActionMenuPosition = OpenPositionsActionMenu;
  public actionMenuList: ActionButton[] = [];
  public mobileActionMenuList: ActionButton[] = [];
  public ActionButtonType = ActionButtonType;

  private originalActionMenu: ActionButton[] = [];

  constructor(
    protected router: Router,
    protected breakPointService: BreakPointService,
    private permissionsService: NgxPermissionsService,
  ) {
    super();

    let count = 0;

    this.breakPointService.screenSize$.pipe(takeUntil(this.destroy$)).subscribe((size) => {
      this.isMobile = size !== ScreenSize.desktop;

      if (this.actionMenuComponent?.isOpen) {
        this.actionMenuComponent.isOpen = false;
      }

      if (count) {
        this.parseActions();
      }

      count++;
    });
  }

  @Input()
  public set actionMenu(value: ActionButton[]) {
    this.originalActionMenu = value;
    this.actionMenuList = value;
  }

  @HostListener('window:resize', [])
  public onWindowScroll(event: MouseEvent): void {
    setTimeout(() => {
      this.breakPointService.screenSize$.next(this.breakPointService.getScreenSize());
    }, 150);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.actionButtons?.length) {
      this.parseActions();
      this.parseMobileActions();
    }
  }

  public doAction(button: ActionButton): void {
    if (button.disabled) {
      return;
    }

    button.callback();
  }

  private parseActions(): void {
    const menuList: ActionButton[] = this.originalActionMenu;

    this.actionMenuList = menuList?.filter((menu) => {
      if (menu.permissions?.length) {
        return (
          menu.permissions.some((permission) => {
            return this.permissionsService.getPermission(permission);
          }) && !menu.hidden
        );
      }

      return !menu.hidden;
    });
  }

  private parseMobileActions(): void {
    const menuList = [...this.originalActionMenu];

    this.actionButtons.forEach((button) => {
      if (button.type && button.type === ActionButtonType.splitButton && button.subAction?.length) {
        button.subAction.forEach((action) => {
          menuList.push({
            label: action?.label,
            callback: action?.command as any,
            icon: action?.icon,
            hideOnMobile: action?.hideOnMobile,
          });
        });
      } else {
        menuList.push(button);
      }
    });

    this.mobileActionMenuList = menuList.filter((menu) => {
      if (menu?.permissions?.length) {
        return (
          menu.permissions.some((permission) => {
            return this.permissionsService.getPermission(permission);
          }) && !menu.hideOnMobile
        );
      }

      return menu.hideOnMobile ? menu.hidden : !menu.hidden;
    });
  }
}
